import React, { ReactElement } from 'react'
import Button from 'antd/lib/button/button'
import { JEPBaseButtonProps } from '@/components/static/ui/Button/Button.types'
import styles from './GenericButton.module.scss'

export enum GenericButtonType {
    primaryDarkBackground = 'primaryDarkBackground',
    grayDarkBackground = 'grayDarkBackground',
    greenNoBackground = 'greenNoBackground',
    whiteGhost = 'whiteGhost',
}
interface GenericButtonProps extends JEPBaseButtonProps {
    type: GenericButtonType
}

const GenericButtonTypeToStyleClass: Record<GenericButtonType, string> = {
    [GenericButtonType.primaryDarkBackground]: styles.primaryDarkBackground,
    [GenericButtonType.grayDarkBackground]: styles.grayDarkBackground,
    [GenericButtonType.greenNoBackground]: styles.greenNoBackground,
    [GenericButtonType.whiteGhost]: styles.whiteGhost,
}
export const GenericButton: React.FC<GenericButtonProps> = (
    props
): ReactElement => {
    const { text, htmlType, ...buttonProps } = props

    return (
        <div className={styles.genericButton}>
            <Button
                {...buttonProps}
                type={'primary'}
                size={'large'}
                className={GenericButtonTypeToStyleClass[props.type]}
                htmlType={htmlType}
            >
                {text}&nbsp;
            </Button>
        </div>
    )
}
