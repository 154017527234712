import { ApiMethod, EmptyResponse } from 'common/src/api/types'
import { CancelToken } from 'axios'
import {
    GenericContentRequest,
    SpecificContentRequest,
} from 'common/src/api/core/courses/requests'
import {
    AddNotecardToNotecardSetRequest,
    CreateNotecardSetForContentPathRequest,
    CreateNotecardSetRequest,
    GetNotecardsRequest,
    SetNotecardSetNameRequest,
    SyncIndividualNotecardRequest,
    SyncNotecardSetRequest,
} from 'common/src/notecards/requests'
import {
    CreateNotecardSetError,
    INotecardUser,
    NotecardSetState,
    NotecardSetStateData,
    NotecardUserHistoryData,
} from 'common/src/notecards/types'
import { SerializedNotecardSuccessRate } from 'common/src/notecards/notecardSuccessRate'
import {
    NotecardMetadataResponse,
    NotecardSetMetadataResponse,
} from 'common/src/notecards/responses'
import { SpecificContentResponse } from 'common/src/api/core/courses/responses'
import { APIResponseWrapper } from '@/api/types'
import { ContentPath } from 'common/src/ContentPath'
import { callBackend } from '../../server/callBackend'
import { handleSyncIssue } from '@/utils/handleSyncIssue'

const PREFIX = '/wb/notecards/'

export async function getSerializedNotecardSuccessRate(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<SerializedNotecardSuccessRate> {
    return await callBackend<
        GenericContentRequest,
        SerializedNotecardSuccessRate
    >(
        PREFIX + 'getSerializedNotecardSuccessRate',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export async function getNotecardSetMetadata(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<NotecardSetMetadataResponse> {
    return await callBackend<
        GenericContentRequest,
        NotecardSetMetadataResponse
    >(
        PREFIX + 'getNotecardSetMetadata',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export async function getNotecardUserMetadata(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<NotecardMetadataResponse> {
    return await callBackend<GenericContentRequest, NotecardMetadataResponse>(
        PREFIX + 'getNotecardUserMetadata',
        ApiMethod.GET,
        {
            courseName,
        },
        cancelToken
    )
}

export async function getNotecardSet(
    courseName: string,
    id: string,
    cancelToken?: CancelToken
): APIResponseWrapper<NotecardSetState> {
    return await callBackend<SpecificContentRequest, NotecardSetState>(
        PREFIX + 'getNotecardSet',
        ApiMethod.GET,
        {
            courseName,
            id,
        },
        cancelToken
    )
}

export async function createNotecardSet(
    courseName: string,
    notecardIDs: string[],
    name: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<NotecardSetState> {
    return await callBackend<CreateNotecardSetRequest, NotecardSetState>(
        PREFIX + 'createNotecardSet',
        ApiMethod.POST,
        {
            courseName,
            notecardIDs,
            name,
        },
        cancelToken
    )
}

export async function createNotecardSetForContentPath(
    courseName: string,
    contentPath: ContentPath,
    cancelToken: CancelToken = null
): APIResponseWrapper<NotecardSetState | { error: CreateNotecardSetError }> {
    return await callBackend<
        CreateNotecardSetForContentPathRequest,
        NotecardSetState
    >(
        PREFIX + 'createNotecardSetForContentPath',
        ApiMethod.POST,
        {
            courseName,
            contentPath,
        },
        cancelToken
    )
}

export async function createAutoGeneratedNotecardSet(
    courseName: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<NotecardSetState> {
    return await callBackend<GenericContentRequest, NotecardSetState>(
        PREFIX + 'createAutoNotecardSet',
        ApiMethod.POST,
        {
            courseName,
        },
        cancelToken
    )
}

export async function setNotecardSetName(
    courseName: string,
    id: string,
    name: string,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SetNotecardSetNameRequest, EmptyResponse>(
        PREFIX + 'setNotecardSetName',
        ApiMethod.POST,
        {
            courseName,
            id,
            name,
        },
        cancelToken
    )
}

// only updates one notecard at a time
export async function syncNotecardSet(
    courseName: string,
    notecardSetID: string,
    stateData: NotecardSetStateData,
    cancelToken: CancelToken = null
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SyncNotecardSetRequest, EmptyResponse>(
        PREFIX + 'syncNotecardSet',
        ApiMethod.POST,
        {
            courseName,
            id: notecardSetID,
            stateData,
        },
        cancelToken
    )
}

// TODO: COULD just pass in notecard
export async function syncIndividualNotecard(
    courseName: string,
    id: string,
    notecardUserHistoryData: NotecardUserHistoryData,
    cancelToken: CancelToken = null
): APIResponseWrapper<boolean> {
    const result = await callBackend<
        SyncIndividualNotecardRequest,
        EmptyResponse
    >(
        PREFIX + 'syncIndividualNotecard',
        ApiMethod.POST,
        {
            courseName,
            id,
            notecardUserHistoryData,
        },
        cancelToken
    )

    if (result.data.payload === false) {
        handleSyncIssue()
    }

    return result
}

export async function deleteNotecardSet(
    courseName: string,
    notecardSetID: string,
    cancelToken?: CancelToken
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<SpecificContentRequest, EmptyResponse>(
        PREFIX + 'deleteNotecardSet',
        ApiMethod.POST,
        {
            courseName,
            id: notecardSetID,
        },
        cancelToken
    )
}

export async function getNotecard(
    courseName: string,
    notecardID: string,
    cancelToken?: CancelToken
): APIResponseWrapper<INotecardUser> {
    return await callBackend<SpecificContentRequest, INotecardUser>(
        PREFIX + 'getNotecard',
        ApiMethod.GET,
        {
            courseName,
            id: notecardID,
        },
        cancelToken
    )
}

export async function getNotecards(
    courseName: string,
    notecardIDs: string[],
    cancelToken?: CancelToken
): APIResponseWrapper<INotecardUser[]> {
    return await callBackend<GetNotecardsRequest, INotecardUser[]>(
        PREFIX + 'getNotecards',
        ApiMethod.GET,
        {
            courseName,
            notecardIDs,
        },
        cancelToken
    )
}

export async function addNotecardIDToNotecardSet(
    courseName: string,
    notecardSetID: string,
    notecardID: string,
    cancelToken?: CancelToken
): APIResponseWrapper<EmptyResponse> {
    return await callBackend<AddNotecardToNotecardSetRequest, EmptyResponse>(
        PREFIX + 'addNotecardIDToNotecardSet',
        ApiMethod.POST,
        {
            courseName,
            id: notecardSetID,
            notecardID,
        },
        cancelToken
    )
}

export async function getMostRecentNotecardSetID(
    courseName: string
): APIResponseWrapper<SpecificContentResponse> {
    return await callBackend<GenericContentRequest, SpecificContentResponse>(
        PREFIX + 'getMostRecentNotecardSetID',
        ApiMethod.GET,
        {
            courseName,
        }
    )
}
